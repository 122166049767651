import React, { useState, useMemo } from 'react';

interface sidebar { friends: UserStatues[]  }

const Sidebar: React.FC<sidebar> = ({ friends }) => {
  const [isSidebarCollapsed, setSidebarCollapsed] = useState(true);
  const [collapsedCategories, setCollapsedCategories] = useState<{
    [key: string]: boolean;
  }>({
    online: false,
    idle: false,
    offline: false,
    dnd: false,
  });

  const handleToggleSidebar = () => {
    setSidebarCollapsed(!isSidebarCollapsed);
  };

  const handleToggleCategory = (category: string) => {
    setCollapsedCategories((prev) => ({
      ...prev,
      [category]: !prev[category],
    }));
  };

  const groupedFriends = useMemo(() => {
    const groups: { [key: string]: UserStatues[] } = {
      online: [],
      idle: [],
      offline: [],
      dnd: [], 
    };

    friends.forEach((friend) => {
      const status = friend.status.toLowerCase();
      if (groups[status]) {
        groups[status].push(friend);
      } else {
        groups.offline.push(friend); 
      }
    });

    return groups;
  }, [friends]);

  // TODO: FIX THIS FUCKING MESS! THIS IS THE WORSE CODE I'VE SEEN SO FAR
  const getStatusDisplayName = (status: string) => {
    switch (status) {
      case 'online':
        return 'Online';
      case 'idle':
        return 'Idle';
      case 'offline':
        return 'Offline';
      case 'dnd':
        return 'Do Not Disturb';
      default:
        return 'Offline';
    }
  };

  const getStatusIndicatorClass = (status: string) => {
    switch (status) {
      case 'online':
        return 'freen-online';
      case 'idle':
        return 'freen-idle';
      case 'dnd':
        return 'freen-dnd';
      default:
        return 'freen-offline';
    }
  };

  return (
    <div className={`sidebar ${isSidebarCollapsed ? 'collapsed' : ''}`}>
      <button className="toggle-btn" onClick={handleToggleSidebar}>
        <span className="label">Friends</span>
        <span className="arrow">➜</span>
      </button>
      <div className="freen-friends-list">
        {friends.length === 0 &&
            <div className='no-friends'>
              <i className="bi bi-person-x-fill"></i>
              {!isSidebarCollapsed && 
                <>
                  <h2>No friends yet</h2>
                  <p>Do you know someone? Send a friend request to see if they are going to accept it. You don't have anything to lose!</p>
                </>
              }
            </div>
        }
        {Object.entries(groupedFriends).map(([status, friendsList]) => {
          if (friendsList.length === 0) return null;
          return (
            <div key={status}>
              <div
                className="freen-friend-category"
                data-category={status}
                onClick={() => handleToggleCategory(status)}
              >
                <span className="freen-category-text">
                  {getStatusDisplayName(status)} — {friendsList.length}
                </span>
                <span className="freen-category-icon">
                  <span className={`freen-circle-icon ${getStatusIndicatorClass(status)}`}></span>
                  <svg
                    className="freen-down-arrow-icon"
                    width="12"
                    height="12"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M5 7L10 12L15 7"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                  </svg>
                </span>
              </div>
              <div
                className={`freen-friend-list-group ${
                  collapsedCategories[status] ? 'collapsed' : ''
                }`}
                data-category-group={status}
              >
                {friendsList.map((friend) => (
                  <div key={friend.user_id} className="freen-friend">
                    <div className="freen-hexagon-wrapper">
                      <div className="freen-hexagon">
                        <img className='user-avatar' style={{borderRadius: "0"}} src={`/pfp/${friend.user_id}.png`} alt="" />
                      </div>
                      <div className={`freen-status-indicator ${getStatusIndicatorClass(friend.status)}`}></div>
                    </div>
                    <div className="freen-friend-info">
                      <div className="freen-friend-name">{friend.displayname}</div>
                      <div className="freen-status">
                        <span className={`freen-status-dot ${getStatusIndicatorClass(friend.status)}`}></span>{' '}
                        {getStatusDisplayName(friend.status)}
                      </div>
                      {friend.activity && <div className="freen-activity">{friend.activity}</div>}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </div>
      <div className="freen-footer">
          <i className="bi bi-globe-americas"></i>
      </div>
    </div>
  );
};

export default Sidebar;