import React, { useCallback, useState, useEffect } from 'react';
import { useNavigate, useSearchParams} from "react-router-dom";
import webSocket from './components/ws'
import 'bootstrap-icons/font/bootstrap-icons.css';
import './style/index.css';

import Header from './components/Header';
import ServerList from './components/ServerList';
import Settings from './components/Settings';
import Channels from './components/Channels';
import OpenChannel from './components/OpenChannel' 
import Members from './components/Members'
import Sidebar from './components/Sidebar'
import CreateServerMenu from './components/CreateServerMenu'
const Login = React.lazy(() => import('./components/Login'));
const EmailConfirm = React.lazy(() => import('./components/emailConfirm'));



const App: React.FC = () => {
  // Route
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  
  const [loginRequired, setLoginRequired] = useState(false)
  const [linkDiscord, setLinkDiscord] = useState<string | null>(null);

  // User data
  const [channelList, setChannelList] = useState<Channel[]>([]);
  const [guildList, setGuildList] = useState<Guild[]>([]);
  const [selectedChannel, setSelectedChannel] = useState<Channel | null>(null);
  const [selectedGuild, setSelectedGuild] = useState<Guild | null>(null);
  const [channelData, setChannelData] = useState<ChannelData>({})
  const [friends, setFriends] = useState<UserStatues[]>([])
  const [user, setUser] = useState<User | false>(false)
  const [loggedIn, setLoggedIn] = useState(false);
  const [reply, setReply] = useState<Message | undefined>(undefined)

  // Visibility
  const [serverListVisible, setServerListVisible] = useState(false);
  const [serverCreateVisible, setServerCreateVisible] = useState(false);
  const [settingsVisible, setSettingsVisible] = useState(false)


  const serverWheel = useCallback(() => {setServerListVisible(!serverListVisible)}, [serverListVisible]);  
  const serverCreate = useCallback(() => {setServerCreateVisible(!serverCreateVisible)}, [serverCreateVisible]);
  const opeSetings = useCallback(() => {setSettingsVisible(!settingsVisible)}, [settingsVisible])

  // Functionality
  const getChannelById = useCallback((id: number) => channelList.find(channel => channel.channel_id === id), [channelList]);
  const getGuildById = useCallback((id: number) => guildList.find(guild => guild.guild_id === id), [guildList]);
  
  const sendMessage = useCallback((message: Message) => {setChannelData(prevChannelData => ({...prevChannelData, messages: [...(prevChannelData.messages || []), message]}))}, [])
  const updateStatuses = useCallback((UserStatues: UserStatues[]) => {setChannelData(prevChannelData => ({...prevChannelData, UserStatues: [...UserStatues]}))}, [])

  const {ws, wsReady } = webSocket('wss://ws.chat.codeweave.hu', updateStatuses, sendMessage, setChannelData, channelData, setFriends);
  const wsSend = useCallback(async(message: wsMessage) => {
    try {
      await wsReady; 
      if (ws) { ws.send(JSON.stringify(message)) } else { console.error("WS not set") }
    } catch (error) { if (!loginRequired || loggedIn) { console.error("WebSocket connection failed:", error) } }
  }, [ws, wsReady, loginRequired, loggedIn]);

  const CreateGuild = useCallback((guild: CreateGuild) => {
    wsSend(({ op: "CreateGuild", d: guild }));
  }, [wsSend])

  const logout = useCallback(async() => {
    const response = await fetch(`api/logout.php`);
    if (response.status === 200) {
      window.location.reload();
    }
  }, [])

  useEffect(() => {
    if (!selectedGuild) { return }
    setSearchParams({g: `${selectedGuild.guild_id}`})
    const fetchChannelData = async () => {
      const response = await fetch(`api/fetchGuild.php?g=${selectedGuild.guild_id}`);
      const data: Channel[] = await response.json();
      setChannelList(data)
      const selectedChannelData = data.find((channel) => channel.channel_id === selectedChannel?.channel_id) || null;
      if (!selectedChannelData && data[0].channel_id) {
        setSelectedChannel(data[0] || null)
      }
    }
    fetchChannelData()
     .catch(console.error);
  }, [selectedGuild])

  useEffect(() => {
    if (!selectedChannel) { return }
    setSearchParams({g: `${selectedGuild?.guild_id}`, c: `${selectedChannel.channel_id}`})
    const fetchChannelData = async () => {
      const response = await fetch(`api/fetchChannel.php?c=${selectedChannel.channel_id}`);
      const data: ChannelData = await response.json();
      setChannelData({...data, UserStatues: [...(channelData.UserStatues ?? [])]}) 
    }
    fetchChannelData()
      .catch(console.error);
    wsSend({op:"fetchOnline", d: {channelId: selectedChannel.channel_id}})
    wsSend({op: "subscribe", d: {channelId: selectedChannel.channel_id}})
  }, [selectedChannel, wsSend])
  
  useEffect(() => {
    const requestInitData = async () => {
        const response = await fetch('https://chat.codeweave.hu/api/init.php');
        if (!response.ok) { throw new Error(`Error: ${response.statusText}`)}
        const jsonData: initResponse = await response.json();

        const guildFromUrl = searchParams.get("g") || "";
        const channelFromUrl = searchParams.get("c") || "";

        if (jsonData.success) {
          setChannelList(jsonData.guild_channels);
          setGuildList(jsonData.user_guilds);
          setUser(jsonData.user)

          const usedSelectedGuild = guildFromUrl ? Number(guildFromUrl) : jsonData.selected_guild
          const usedSelectedChannel = channelFromUrl ? Number(channelFromUrl) : jsonData.selected_channel

          const selectedGuildData = jsonData.user_guilds.find((guild) => guild.guild_id === usedSelectedGuild) || null;
          setSelectedGuild(selectedGuildData);

          const selectedChannelData = jsonData.guild_channels.find((channel) => channel.channel_id === usedSelectedChannel) || null;
          setSelectedChannel(selectedChannelData);
          setLoggedIn(true)
        } else {
          if (jsonData.login_required) {
            setLoginRequired(true)
          } else {
            throw new Error('Failed to fetch data');
          }
        }

      const queryParams = new URLSearchParams(window.location.search);
      const linkDiscordParam = queryParams.get('link-discord');
      setLinkDiscord(linkDiscordParam);
    };
    requestInitData()
      .catch(console.error);
  }, []);


  
  return (
    loggedIn ? (
      <div className='container'>
        {serverListVisible && <ServerList serverWheel={serverWheel} />}
        {serverCreateVisible && <CreateServerMenu serverCreate={serverCreate} CreateGuild={CreateGuild} />}

        <Header
          guildList={guildList}
          selectedGuild={false}
          user={user}
          
          serverWheel={serverWheel}
          serverCreate={serverCreate}
          setSelectedGuild={setSelectedGuild}
          opeSetings={opeSetings}
        />

        {settingsVisible ? <Settings user={user} logout={logout}/> : (
          
        <div id="main-container" className="main-container">
          <Sidebar
            friends={friends}
          />

          <Channels 
            guildChannels={channelList}
            selectedChannel={selectedChannel}
            setSelectedChannel={setSelectedChannel}
            getChannelById={getChannelById}
            selectedGuild={selectedGuild}
          />

          <OpenChannel
            selectedChannel={selectedChannel}
            channelData={channelData}
            user={user}
            sendMessage={sendMessage}
            wsSend={wsSend}
            reply={reply}
            setReply={setReply}
          />

          <Members 
            channelData={channelData}
          /> 

        </div> 
        )}
      </div>
    ) : (
      linkDiscord ? <EmailConfirm email={linkDiscord}/> : (
        loginRequired ? <Login/> : <div className="loaderparent"><span className="loader"></span></div>
      )
    )
  );
}

export default App;