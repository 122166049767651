import React from "react";
import { Users, CheckCircle, AtSign, Shield, Code, BarChart2, Activity, MessageSquare, UserPlus } from "react-feather";

const MemberList: React.FC = () => {
  return (
        <div className="profile-preview">
          <div className="preview-header">
            <div className="preview-avatar">
              <img src="/api/placeholder/50/50" alt="User" />
            </div>
            <div className="preview-user-info">
              <div className="preview-username">
                Placeholder Name
                <CheckCircle />
              </div>
              <div className="preview-tag">
                <AtSign />
                placeholder#1234
              </div>
            </div>
          </div>

          <div className="preview-content">
            <div className="preview-section">
              <div className="preview-section-title">
                <Shield />
                Szerepkörök
              </div>
              <div className="preview-roles">
                <div className="preview-role">
                  <Code />
                  Fejlesztő
                </div>
                <div className="preview-role">
                  <Shield />
                  Admin
                </div>
              </div>
            </div>

            <div className="preview-section">
              <div className="preview-section-title">
                <BarChart2 />
                Statisztika
              </div>
              <div className="preview-stats">
                <div className="preview-stat">
                  <div className="stat-value">128</div>
                  <div className="stat-label">Üzenet</div>
                </div>
                <div className="preview-stat">
                  <div className="stat-value">2.4k</div>
                  <div className="stat-label">Karma</div>
                </div>
                <div className="preview-stat">
                  <div className="stat-value">1 év</div>
                  <div className="stat-label">Tag</div>
                </div>
              </div>
            </div>

            <div className="preview-section">
              <div className="preview-section-title">
                <Activity />
                Aktivitás
              </div>
              <div className="activity-card">
                <div className="activity-icon">
                  <Code />
                </div>
                <div className="activity-info">
                  <div className="activity-title">Placeholder Activity</div>
                  <div className="activity-subtitle">2 órája aktív</div>
                </div>
              </div>
            </div>

            <div className="preview-actions">
              <button className="preview-button secondary">
                <MessageSquare />
                Üzenet
              </button>
              <button className="preview-button primary">
                <UserPlus />
                Követés
              </button>
            </div>
          </div>
        </div>
  );
};

export default MemberList;
