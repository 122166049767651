import React,{useState} from 'react';

interface promptMenu { 
    promptData: promptData; 
    promptHandle: (submit: boolean, value?: string) => void
 }
const PromptMenu: React.FC<promptMenu> = ({ promptData, promptHandle }) => {
    const [inputValue, setInputValue] = useState<string>("");

    return (
        <div className="prompt-overlay">
            <div className="prompt-container">
                <h1>{promptData.header}</h1>
                <p>{promptData.subtext}</p>
                <label htmlFor="promptInput">{promptData.responseText}</label>
                <input
                    type="text"
                    placeholder={promptData.inputPlaceholder}
                    name="promptInput"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                />
                <button onClick={() => {promptHandle(false) }}>{promptData.cancelText}</button>
                <button onClick={() => {promptHandle(true, inputValue) }}>{promptData.submitText}</button>
            </div>
        </div>
    );
};

export default PromptMenu;