import {useEffect, useState } from 'react';

const useWebSocket = (
    url: string, 
    updateStatuses: (statuses: UserStatues[]) => void, 
    sendMessage: (message: Message) => void, 
    setChannelData: (ChannelData: ChannelData) => void, 
    ChannelData: ChannelData,
    setFriends: (friends: UserStatues[]) => void
) => {
    const [ws, setWs] = useState<WebSocket | null>(null);
    const [wsReady, setWsReady] = useState<Promise<void>>();

    useEffect(() => {
        const token = getCookie('token');
        if (!token) {
            setWsReady(Promise.reject("Token not found").catch(error => error));
            return;
        }
        
        const websocket = new WebSocket(url);
        setWs(websocket);

        const wsReadyPromise = new Promise<void>((resolve, reject) => {
            websocket.onopen = () => {
                const token = getCookie('token');
                websocket.send(JSON.stringify({ op: "Authentication", d: {token: token} }));
            };

            websocket.onerror = (err) => {
                console.error('WebSocket error:', err);
                reject(err);
            };

            websocket.onmessage = (event) => {
                const response = JSON.parse(event.data);
                switch (response.op) {
                    case "heartbeat":
                        websocket.send(JSON.stringify({ op: 'heartbeat', d:{}}));
                        break;

                    case "Authentication":
                        if (!response.success) {
                            console.error("Failed to authenticate:", response.error);
                            reject(response.error);
                            return
                        }

                        resolve();
                        setFriends(response.d.Friends)
                        break;

                    case "fetchOnline":
                        if (response.success) {
                            updateStatuses(response.d.users)
                        } else {
                            console.error(response.error);
                        }
                        break;

                    case "Message":
                        sendMessage(response.d)
                        break;

                    case "CreateGuild":
                        
                        break;
                    case "MessageCallback":
                        console.log("response:",response)
                        console.log("ChannelData:", ChannelData.messages)
                        if (response.success) {
                            if (!ChannelData.messages) { return } 
                            const updatedMessages: Message[] = ChannelData.messages.map((message) => {
                                if (message.message_id === response.tempId) {
                                    console.log({ ...message, message_id: response.messageId });
                                    return { ...message, message_id: response.messageId };
                                }
                                return message;
                            });
                            setChannelData({...ChannelData, messages: updatedMessages})
                        } else {
                            if (!ChannelData.messages) { return } 
                            const updatedMessages: Message[] = ChannelData.messages.map((message) => {
                                if (message.message_id === response.tempId) {
                                    return { ...message, error: response.error || "Unknown error" };
                                }
                                return message;
                            });
                            setChannelData({...ChannelData, messages: updatedMessages})
                        }
                        break;
                        
                    default:
                        break;
                }
            };
        });

        setWsReady(wsReadyPromise);

        return () => {
            websocket.close();
        };
    }, [url, updateStatuses, setChannelData, sendMessage, setFriends]);

    return { ws, wsReady };
};

const getCookie = (name: string): string | null => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop()?.split(';').shift() || null;
    return null;
};

export default useWebSocket;