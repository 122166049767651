import React, { useState } from "react";
import '../style/CreateServerMenu.css';

interface createServerMenu { serverCreate: () => void,  CreateGuild: (guild: CreateGuild) => void }

const CreateServerMenu: React.FC<createServerMenu> = ({serverCreate, CreateGuild}) => {
    const [serverName, setServerName] = useState<string>("");
    const [serverType, setServerType] = useState<string>("public");
    const [serverIcon, setServerIcon] = useState<File | null>(null);
    const [serverIconPreview, setServerIconPreview] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);

    const handleCreateServer = () => {
      if (!serverName.trim()) {
          setError("Server name cannot be empty.");
          return;
      }
      setError(null);

      const serverData: CreateGuild = {
          name: serverName,
          type: serverType,
          icon: serverIconPreview, 
      };

      CreateGuild(serverData)
      serverCreate()
    };

    const handleUploadClick = () => {
        const fileInput = document.createElement("input");
        fileInput.type = "file";
        fileInput.accept = "image/*";
        fileInput.onchange = (e: Event) => {
            const target = e.target as HTMLInputElement;
            if (target.files && target.files[0]) {
                const file = target.files[0];
                setServerIcon(file);

                const reader = new FileReader();
                reader.onload = () => {
                    if (reader.result) {
                        setServerIconPreview(reader.result as string);
                    }
                };
                reader.readAsDataURL(file);
            }
        };
        fileInput.click();
    };

    return (
      <div className="modal">
        <h2>Customize Your Server</h2>
        <p>Give your new server personality with a unique name and icon. You can always change it later.</p>

        <div className="upload-button" onClick={handleUploadClick}>
          <span>{serverIcon ? 
              <div className="image-preview">
                <img 
                  src={serverIconPreview ? serverIconPreview : ""} 
                  alt="Server Icon Preview" 
                  style={{  width: "100px",  height: "100px",  borderRadius: "50%",  margin: "10px 0" }} 
                />
              </div>
          : "UPLOAD"}</span>
        </div>

        <div className="server-name">
          <input
            type="text"
            placeholder="Server name"
            value={serverName}
            onChange={(e) => setServerName(e.target.value)}
          />
        </div>
        {error && <p style={{ color: "red", fontSize: "0.9em" }}>{error}</p>}

        <p>By creating a server, you agree to the Community Guidelines.</p>
        <div className="server-privacy">
          <label
            htmlFor="privacy-select"
            style={{ color: "#b9bbbe", fontSize: "1em" }}
          >
            Choose the server type:
          </label>
          <select
            id="privacy-select"
            style={{ width: "100%", padding: "10px", margin: "15px 0", borderRadius: "12px", border: "none", backgroundColor: "#202225", color: "#ffffff", fontSize: "1em" }}
            value={serverType}
            onChange={(e) => setServerType(e.target.value)}
          >
            <option value="public">Public</option>
            <option value="friends">Friends can join</option>
            <option value="Private">Private</option>
          </select>
        </div>

        <div className="buttons">
          <button className="cancel-button" onClick={() => serverCreate()}>Back</button>
          <button className="create-button" onClick={handleCreateServer}>Create</button>
        </div>
      </div>
    );
};

export default CreateServerMenu;
