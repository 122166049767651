import React,{useState, useCallback} from 'react';
import ContextMenu from './ContextMenu';
import Promt from './promptMenu';


interface ChannelList { guildChannels: Channel[]; selectedChannel: Channel | null; selectedGuild: Guild | null; setSelectedChannel: (Channel: Channel) => void; getChannelById: (channelId: number) => Channel | undefined }

const Channels: React.FC<ChannelList> = ({ guildChannels, selectedChannel, setSelectedChannel, getChannelById, selectedGuild }) => {
    const [menuPosition, setMenuPosition] = useState<{ top: number; left: number } | null>(null);
    const [menuOpen, setMenuOpen] = useState(false);
    const [contextData, setContextData] = useState<{ type: 'channel' | 'channelVoid', channel?: Channel} | null>(null);

    const [promptVisible, setPromptVisible] = useState(false)
    const [prompt, setpromt] = useState({ header: "", subtext: "", responseText: "Your Response", inputPlaceholder: "Answer", cancelText: "Cancel", submitText: "Submit" })


    const handleContextMenu = (e: React.MouseEvent, channel: Channel) => {
        e.preventDefault();
        e.stopPropagation()
        const x = e.clientX;
        const y = e.clientY;
        setMenuPosition({ top: y, left: x });
        setContextData({type: 'channel', channel: channel})
        setMenuOpen(true);
    };
    
    const handleVoidContextMenu = (e: React.MouseEvent) => {
        e.preventDefault();
        const x = e.clientX;
        const y = e.clientY;
        setMenuPosition({ top: y, left: x });
        setContextData({type: 'channelVoid'})
        setMenuOpen(true);
    };

    const handleCloseMenu = () => {
        setMenuOpen(false);
    };
    
    const promptHandle = useCallback((submit: boolean, value?: string) => {
        if (!submit) {
            setPromptVisible(false)
            return
        }
        console.log(`Create channel: ${value}`)
        // TODO: create channel
    }, [])

    const handleEvents = useCallback((event: string, dataaray: Message | Channel | User | undefined) => {
        // markread, copylink, delete, CreateChannel
        if (event === 'CreateChannel') {
            setpromt(
                { 
                    header: "Created channel name", 
                    subtext: "Set a name for the channel being created", 
                    responseText: "", 
                    inputPlaceholder: "Channel", 
                    cancelText: "Cancel", 
                    submitText: "Create channel"
                }
            )
            setPromptVisible(true)
            handleCloseMenu()
        }
        if (!dataaray) { return }

        handleCloseMenu()
    },[])

    return (
        <>
            {promptVisible && <Promt promptData={prompt} promptHandle={promptHandle} />}
            <div className="channels-sidebar">
                <div className="channels-container" onContextMenu={(e) => handleVoidContextMenu(e)}>
                    <div className="channel-category">
                        Text channels
                        <span>+</span>
                    </div>

                    {guildChannels.map((channel) => {
                        const { channel_id, name, type } = channel;
                        const channelTypeSymbol = type === 'voice' ? '🔊' : '#';
                        const isSelected = selectedChannel && selectedChannel.channel_id === channel_id;
                        const handleClick = type === 'text' ? () => {
                            const selectedChannel = getChannelById(channel_id);
                            if (selectedChannel) {
                            setSelectedChannel(selectedChannel);
                            }
                        }
                        : undefined;

                        return (
                            <button key={channel_id} 
                            
                            onContextMenu={(e) => handleContextMenu(e, channel)}
                            onClick={handleClick} 
                            style={{ cursor: type === 'text' ? 'pointer' : 'default' }}>
                                <div id={isSelected ? 'selected-channel' : undefined} className="channel">
                                    {channelTypeSymbol} {name}
                                </div>
                            </button>
                        );
                    })}
                </div>
                <ContextMenu
                    open={menuOpen}
                    position={menuPosition || { top: 0, left: 0 }}
                    contextData={contextData}
                    
                    onClose={handleCloseMenu}
                    eventHandler={handleEvents}
                />
                <div className='server-banner'>
                    <img className='banner-img' src="https://cdn.discordapp.com/banners/1242297152970166282/9c29762c2f22985bf4faf9714bc8bd5f.webp?size=240" alt="" />
                </div>
                <div className="server-header">
                    {selectedGuild?.name}
                    <div className="avatar-container">
                        <img
                            className="profile-avatar"
                            src={`/pfp/${selectedGuild?.owner_id}.png`}
                            alt="Profile Avatar"
                        />
                        <img
                            className="crown-icon"
                            src="crown.png"
                            alt="Crown"
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Channels;