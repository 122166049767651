import React, { useRef, useEffect, useState } from 'react';

import { CornerUpLeft, Edit2, MapPin, Share2, Copy, Bookmark, Shield, Globe, ChevronRight, Trash2, Eye, FilePlus } from 'react-feather';
import '../style/ContextMenu.css';

interface ContextData {  type: 'message' | 'channel' | 'user' | 'channelVoid'; channel?: Channel; message?: Message }

interface ContextMenuProps {
  open: boolean;
  position: { top: number; left: number };
  contextData: ContextData | null;
  onClose: () => void;
  eventHandler: (event: string, dataaray?: Message | Channel | User | undefined) => void;
}

const ContextMenu: React.FC<ContextMenuProps> = ({ open, position, contextData, onClose, eventHandler }) => {
  const menuRef = useRef<HTMLDivElement>(null);
  const [adjustedPosition, setAdjustedPosition] = useState(position);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    const adjustMenuPosition = () => {
      if (menuRef.current) {
        const menuWidth = menuRef.current.offsetWidth;
        const menuHeight = menuRef.current.offsetHeight;
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;

        let adjustedTop = position.top;
        let adjustedLeft = position.left;

        if (adjustedTop + menuHeight > windowHeight) {
          adjustedTop = windowHeight - menuHeight - 10; // 10px margin
        }

        if (adjustedLeft + menuWidth > windowWidth) {
          adjustedLeft = windowWidth - menuWidth - 10; // 10px margin
        }

        if (adjustedLeft < 0) {
          adjustedLeft = 10; // 10px margin
        }

        if (adjustedTop < 0) {
          adjustedTop = 10; // 10px margin
        }

        setAdjustedPosition({ top: adjustedTop, left: adjustedLeft });
      }
    };

    adjustMenuPosition();

    document.addEventListener('click', handleClickOutside);
    document.addEventListener('keydown', handleKeyDown);
    
    window.addEventListener('resize', adjustMenuPosition); // Re-adjust position on window resize

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('click', handleClickOutside);
      window.removeEventListener('resize', adjustMenuPosition);
    };
  }, [position, onClose]);

  if (!open || !contextData) return null;

  const renderMenuItems = () => {
    switch (contextData.type) {
      case 'message':
        return (
          <>
            <div className="context-section">
              <div className="menu-item">
                <button className="action-button" onClick={() => eventHandler('reply', contextData.message)}>
                  <CornerUpLeft size={16} />
                  <span>Reply</span>
                </button>
              </div>

              <div className="menu-item">
                <button className="action-button" onClick={() => eventHandler('edit', contextData.message)}>
                  <Edit2 size={16} />
                  <span>Edit</span>
                </button>
              </div>

              <div className="menu-item">
                <button className="action-button" onClick={() => eventHandler('pin', contextData.message)}>
                  <MapPin size={16} />
                  <span>Pin</span>
                </button>
              </div>
            </div>

            <div className="menu-separator"></div>

            <div className="context-section">
              <div className="menu-item">
                <button className="action-button" onClick={() => eventHandler('share', contextData.message)}>
                  <Share2 size={16} />
                  <span>Share</span>
                </button>
              </div>

              <div className="menu-item">
                <button className="action-button" onClick={() => eventHandler('copy', contextData.message)}>
                  <Copy size={16} />
                  <span>Copy text</span>
                </button>
              </div>
            </div>

            <div className="menu-separator"></div>
            <div className="context-section">
              <div className="menu-item">
                <button className="action-button" onClick={() => eventHandler('save', contextData.message)}>
                  <Bookmark size={16} />
                  <span>Save</span>
                </button>
              </div>

              <div className="menu-item">
                <button className="action-button" onClick={() => eventHandler('moderate', contextData.message)}>
                  <Shield size={16} />
                  <span>Moderate</span>
                </button>
              </div>
              
              <div className="menu-item">
                <button className="action-button" onClick={() => eventHandler('translate', contextData.message)}>
                  <Globe size={16} />
                  <span>Translate</span>
                  <ChevronRight size={12} className="submenu-arrow" />
                </button>
              </div>
            </div>

            <div className="menu-separator"></div>

            <div className="context-section">
              <div className="menu-item danger">
                <button className="action-button" onClick={() => eventHandler('delete', contextData.message)}>
                  <Trash2 size={16} />
                  <span>Delete</span>
                </button>
              </div>
            </div>
          </>
        );
      case 'channel':
        return (
          <>
            <div className="context-section">
              <div className="menu-item">
                <button className="action-button" onClick={() => eventHandler('markread', contextData.channel)}>
                  <Eye size={16} />
                  <span>mark as read</span>
                </button>
              </div>
              <div className="menu-item">
                <button className="action-button" onClick={() => eventHandler('copylink', contextData.channel)}>
                  <Copy size={16} />
                  <span>copy link</span>
                </button>
              </div>
            </div>
            <div className="context-section">
              <div className="menu-item danger">
                <button className="action-button" onClick={() => eventHandler('delete', contextData.channel)}>
                  <Trash2 size={16} />
                  <span>Delete channel</span>
                </button>
              </div>
            </div>
          </>
        );
      case 'channelVoid':
        return (
            <>
              <div className="context-section">
                <div className="menu-item">
                  <button className="action-button" onClick={() => eventHandler('CreateChannel')}>
                    <FilePlus size={16} />
                    <span>Create channel</span>
                  </button>
                </div>
              </div>
            </>
          );
      default:
        return null;
    }
  };

  return (
    <div
      id="context-menu"
      ref={menuRef}
      style={{position: 'absolute', top: adjustedPosition.top, left: adjustedPosition.left}}
    >
      {renderMenuItems()}
    </div>
  );
};

export default ContextMenu;
